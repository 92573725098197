<template>
    <div>
        <b-card>
            <validation-observer ref="courseFormValidator">  
                <form ref="editForm" >
                    <b-row class="my-1">
                        <b-col  md="3" sm="2">
                            <b-form-group label="Course Name" label-for="courseName">     
                                <validation-provider
                                    #default="{ errors }"
                                    name="courseName"
                                    rules="required"
                                >
                                <b-form-input
                                    id="courseName"
                                    v-model="course.name"
                                    type="text"  
                                    :disabled='isLoading'
                                    name='courceName'                          
                                    placeholder="Enter Text"
                                    :state="errors.length > 0 ? false:null"
                                    oncopy="return false" 
                                    onpaste="return false" 
                                    autocomplete='off'
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col md="3" sm="2" class="mb-1 mb-md-0">
                            <b-form-group class="mb-0" label="Field" label-for="field">    
                            <validation-provider
                                    #default="{ errors }"
                                    name="field"
                                    rules="required"
                            > 
                            <v-select  
                                v-model="course.field"            
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"                               
                                :options="course.fieldArry"  
                                :reduce="name => name.id" label="name"                           
                                :state="errors.length > 0 ? false:null"
                                :clearable="false"                           
                                Onkeydown="return false"
                                name="field" 
                                :disabled='isLoading'
                                read
                                id='field'                           
                                class="per-page-selector d-inline-block mx-10 w-100"
                                oncopy="return false" 
                                onpaste="return false"              
                            />    
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>                       
                        </b-form-group>
                        </b-col>
                        <b-col md="3" sm="2" class="mb-1 mb-md-0">
                            <b-form-group class="mb-0" label="University Name" label-for="universityName">     
                            <validation-provider
                                    #default="{ errors }"
                                    name="universityName"
                                    rules="required"
                            > 
                            <v-select  
                                v-model="course.universityName"            
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="course.universityArry"                               
                                :clearable="false"  
                                :reduce="name => name.id" label="name"     
                                :state="errors.length > 0 ? false:null"                     
                                Onkeydown="return false"
                                :disabled='isLoading'
                                name="universityName" 
                                id='universityName'
                                class="per-page-selector d-inline-block mx-7 w-100"
                                oncopy="return false" 
                                onpaste="return false"              
                            /> 
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>                                
                        </b-form-group>
                        </b-col>
                        <b-col md="3" sm="2">
                            <b-form-group class="mb-0" label="Intake" label-for="intake">                        
                            <b-form-checkbox-group
                                v-model="course.intake"
                                :options="course.intakeOptions"
                                class="demo-inline-spacing"
                                id='intake'
                                :disabled='isLoading'
                                value-field="item"
                                text-field="name"
                            />                
                        </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="my-1">             
                        <b-col md="3" sm="2">
                            <b-form-group class="mb-0" label="IELTS/TOEFL Band Scores" label-for="bandScore">     
                            <b-form-input
                                id="bandScore"
                                v-model="course.bandScore"
                                type="text"   
                                :disabled='isLoading'                        
                                placeholder="Enter Text"
                                oncopy="return false" 
                                onpaste="return false" 
                                @keypress="isOnlyDecimal($event)"
                            />
                        </b-form-group>                 
                        </b-col>                    
                        <b-col md="3" sm="2">
                            <b-form-group class="mb-0" label="German Language" label-for="germanLang">    
                                <b-form-radio-group
                                    v-model="course.germanLang"
                                    :options="course.germanLangOption"
                                    :state="state"
                                    :disabled='isLoading'
                                    class="demo-inline-spacing"
                                    name="radio-validation"
                                >                               
                                </b-form-radio-group>
                            </b-form-group>
                        </b-col>    
                        <b-col md="3" sm="2">
                            <b-form-group class="mb-0" label="Mode of Application" label-for="applicationMode">    
                                <b-form-radio-group
                                    v-model="course.applicationMode"
                                    :options="course.applicationModeOption"                               
                                    class="demo-inline-spacing"
                                    id="applicationMode"
                                    :disabled='isLoading'
                                    name="applicationmode"
                                >                               
                                </b-form-radio-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="3" sm="2">
                            <b-form-group class="mb-0" label="Application Deacline" label-for="applicationdecline">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Application Decline"
                                    rules="required"
                                >
                                <b-form-input
                                    id="example-input"
                                    v-model="course.applicationDecline"
                                    type="date"
                                    placeholder="YYYY-MM-DD"
                                    autocomplete="off"
                                    show-decade-nav
                                    :state="errors.length > 0 ? false:null"
                                    :max="new Date()"
                                    :disabled='isLoading'
                                     class="input-date"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider> 
                            </b-form-group>
                        </b-col>    
                    </b-row>
                    <b-row class="my-1">
                        <b-col  md="6" sm="2">
                            <b-form-group class="mb-0" label="Remark" label-for="remark">  
                                <b-form-textarea
                                id="textarea-small"
                                size="sm"
                                :disabled='isLoading'
                                v-model="course.remark"
                                placeholder="Enter Text"
                            ></b-form-textarea>  
                            </b-form-group>
                        </b-col>
                    </b-row>
                </form>
            </validation-observer>
        </b-card>
        <!-- <div v-if='isLoading' class="loading"></div> -->
        <div v-if="isLoading" class="loading loading-custom">         
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>     
        </div>
    </div>
</template>
<script>
import {  extend ,ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules'
import {
  BButton, BFormGroup, BFormInput, BModal, BListGroup, BListGroupItem, BPagination, BCol, BCard, BRow, BTable, BFormSelect, BImg, BFormCheckboxGroup, BFormRadioGroup, BFormDatepicker, BFormTextarea
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { ValidationFunction } from '@/utils/globalValidations.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// FIREBASE IMPORT
import firebase from "firebase"
import { dbCollections } from '@/utils/firebaseCollection.js';
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import * as inserQuery from '@/utils/FirebaseQueries/insertQueries/insertQueries.js';
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js';
extend('required',required)
export default{
    components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BPagination,
    BCol,
    BCard,
    BRow,
    BTable,
    BFormSelect,
    BImg,
    VSelect,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BFormDatepicker,
    BFormTextarea,
    ToastificationContent   
    },
    data(){
        return{
            course:{
                name:'',
                field:'',
                fieldArry:[],
                universityName:'',
                universityArry:[],
                intake:['WS'],
                intakeOptions: [
                    { item: 'SS', name: 'SS' },
                    { item: 'WS', name: 'WS' },                  
                ],
                bandScore:'',
                germanLang:'None',
                germanLangOption:[
                    { text: 'None', value: 'None' },
                    { text: 'A1', value: 'A1' },
                    { text: 'A2', value: 'A2' },
                    { text: 'B1', value: 'B1' },
                    { text: 'B2', value: 'B2' },
                ],   
                applicationMode:'Uniassist',
                applicationModeOption:[
                    { text: 'Uniassist', value: 'Uniassist' },
                    { text: 'Direct', value: 'Direct' },                 
                ],
                applicationDecline:'',
                remark:''
            },
            allFields:[],
            allUniversity:[],
            isLoading:false
        }
    },
    watch: {
        $route: function () {        
            this.getData();          
        }
    },
    created(){
        var self = this;
        self.getData();
        // ON CANCEl
        self.$root.$on('cancelCourse',()=>{
            if(self.isLoading == false){
            self.$router.push({name:'course-mgmt'})
            self.$root.$emit('stopSpinner')
            }
        })
        // ON SAVE
        self.$root.$on('saveCourse',()=>{
            if(self.$refs.courseFormValidator){
            self.$refs.courseFormValidator.validate().then(success=>{
                if(success)
                {
                    self.isLoading = true;
                    let fieldData = {};
                    let intake = '';
                    let universityData = {};
                    if(self.course.fieldArry.length > 0)
                    {
                       let findFieldInd = self.course.fieldArry.findIndex(ele=>{
                           return ele.id == self.course.field
                       })
                       if(findFieldInd > -1){
                           fieldData = self.course.fieldArry[findFieldInd];
                       } 
                    }
                    if(self.course.universityArry.length > 0)
                    {
                       let findUniInd = self.course.universityArry.findIndex(ele=>{
                           return ele.id == self.course.universityName
                       })
                       if(findUniInd > -1){
                           universityData = self.course.universityArry[findUniInd];
                       } 
                    }
                    if(self.course.intakeOptions.length > 0){                        
                        intake = self.course.intake;
                    }
                    let saveObj = {
                        'applicationDecline': new Date(self.course.applicationDecline) ? new Date(self.course.applicationDecline) : new Date(),
                        'bandScores': self.course.bandScore,
                        'courseName': self.course.name,
                        'fieldData': fieldData,
                        'germanLanguage':self.course.germanLang,
                        'intake':intake,
                        'modeOfApplication':self.course.applicationMode,
                        'remark':self.course.remark,
                        'universityData':universityData,
                        'createdAt' : firebase.firestore.FieldValue.serverTimestamp(),
                        'updatedAt' : firebase.firestore.FieldValue.serverTimestamp(),
                        'isDeleted': false,
                    }
                    inserQuery.addDataToRootCollection(dbCollections.COURSES,saveObj,res=>{
                        console.log(res,"?")
                        if(res.id){
                            let updateField = {
                                'id': res.id
                            }
                            updateQuery.updateRootCollectionDataByDocId(dbCollections.COURSES,res.id,updateField,updateRes=>{
                                console.log(updateRes);
                                self.clearFields();
                                self.isLoading = false;
                                self.$root.$emit('stopSpinner')
                                self.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: 'Document Added Successfully',
                                            icon: 'CoffeeIcon',
                                            variant: 'success',                               
                                        },
                                })
                            })
                        }
                                      
                    })                                      
                }
            }) 
            }   
        })
    },
    computed: {
        state() {
        return Boolean(this.value)
        },
    },
    methods:{
        getData(){
            let self = this;
             getQuery.getDataWithSpecificWhereCondition(dbCollections.FIELDS,'isDeleted','==',false,res=>{   
                 self.course.fieldArry = [];
                 self.course.fieldArry = [];
                 self.allFields = [];
                 if(res){
                    self.allFields = res;
                    if(self.allFields.length > 0){
                        self.allFields.forEach(values=>{
                            self.course.fieldArry.push({'name':values.fieldName,'id':values.id})
                        })
                    }
                 }
             })
             getQuery.getDataWithSpecificWhereCondition(dbCollections.UNIVERSITIES,'isDeleted','==',false,res=>{   
                 self.course.universityArry = [];
                 self.allUniversity = [];
                 if(res){
                    self.allUniversity = res;
                    if(self.allUniversity.length > 0){
                        self.allUniversity.forEach(values=>{
                            self.course.universityArry.push({'name':values.universityName,'id':values.id})
                        })
                    }
                 }
             })
        },
        isOnlyDecimal(event) {
            ValidationFunction.OnlyFloatWithNumber(event);
            // ValidationFunction.OnlyFloatWithNumber(event);
        },       
        clearFields(){
            var self = this;
            self.course.name='';
            self.course.field='';               
            self.course.universityName='';              
            self.course.intake=['WS'];               
            self.course.bandScore='';
            self.course.germanLang='None';                 
            self.course.applicationMode='Uniassist';              
            self.course.applicationDecline='';
            self.course.remark='';         
            this.$refs.courseFormValidator.reset()
        }
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.per-page-selector {
  width: 170px;
}
.position-relative.table-responsive thead tr th{
  white-space: nowrap !important;
}
/* .position-relative.table-responsive tbody tr td{
  white-space: nowrap !important;
} */

.s_modal_title{
    width: 100%;
    
    font-weight: 600;
    font-size: 20px;
}


@-webkit-keyframes rotate {
  /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>